<!--
 * @Description: 邀请页面
 * @Date: 2022-03-16 16:58:10
 * @LastEditTime: 2022-03-29 15:57:33
-->
<template>
  <div class="invite-container">
    <remote_nav></remote_nav>
    <div class="content">
      <div class="param-container" style="display: none">
        <remote_info_input
            label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></remote_info_input>
        <remote_info_input
            label="secretKey" @change="handleValueChange($event, 'secretKey')"></remote_info_input>
      </div>
<!--      <div>{{this.$route.path}}</div>-->
<!--      <div class="invite-tips">-->
<!--        您被邀请参加远程诊疗！-->
<!--      </div>-->
      <!-- rtc 房间 -->
      <remote_room
        style="margin-top: 5px"
        type="invite"
        :sdkAppId="Number(sdkAppId)"
        :secretKey="secretKey"
        :inviteUserSig="userSig"
        :userId="userId"
        :roomId="Number(roomId)"></remote_room>
    </div>
  </div>
</template>

<script>
import remote_nav from '@/components/remote_nav.vue';
import remote_room from '@/components/remote_room';
import { getUrlParam, clearUrlParam } from '@/utils/utils.js';
import remote_info_input from "@/components/remote_info_input.vue";

export default {
  name: 'InviteApp',
  data() {
    return {
      type:'invite',
      sdkAppId: 0,
      secretKey: '',
      userSig: '',
      userId: '',
      roomId: 0,
    };
  },
  components: {
    remote_info_input,
    remote_nav,
    remote_room,
  },
  methods:{
    handleValueChange(value, key) {
      this[key] = value;
    },
  },
  mounted() {
    this.sdkAppId = Number(getUrlParam('sdkAppId'));
    this.userSig = getUrlParam('userSig');
    this.userId = getUrlParam('userId');
    this.roomId = Number(getUrlParam('roomId'));
    clearUrlParam();
  },
};
</script>

<style lang="scss" scoped>
 .content {
    width: 100%;
    margin: 0 auto;
    //max-width: 1320px;
    //padding-top: 30px;
    &.content-mobile {
      width: 100%;
      //padding: 30px 16px 20px;
    }
    .invite-tips {
      width: 100%;
      height: 60px;
      color: #084298;
      background-color: #cfe2ff;
      position: relative;
      padding: 1rem 1rem;
      margin-bottom: 1rem;
      border: 1px solid #b6d4fe;
      border-radius: 0.25rem;
    }
 }
</style>

